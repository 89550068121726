import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "species", "detections", "stations" ]
  static values = { url: String }

  connect () {
    fetch(this.urlValue)
      .then(response => response.json())
      .then(data => {
        this.speciesTarget.textContent = this.format(data.stats.species)
        this.detectionsTarget.textContent = this.format(data.stats.detections)
        this.stationsTarget.textContent = this.format(data.stats.stations)
      })
      .then(() => {
        this.speciesTarget.classList.remove('loading')
        this.detectionsTarget.classList.remove('loading')
        this.stationsTarget.classList.remove('loading')
      })
  }

  format (value) {
    if (value) {
      return value.toLocaleString()
    } else {
      return '?'
    }
  }
}
